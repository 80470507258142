export const environment = {
    EnvironmentName: "UAT",
    Endpoints: {
        base: "https://uat-be-scale-up.dev3.fcrtech.cz",
        baseApi: "https://uat-be-scale-up.dev3.fcrtech.cz",
        authUrl: "https://dev-fcrauth.dev4.fcrtech.cz"
    },
    MFE: {
        ListingForm: {
            name: 'listing-form',
            path: '//dev-mfe.dev4.fcrtech.cz/listing-form.js'
        }
    },
    SeeOnlineUrl: {
        be: "https://uat-goudengids-be.dev4.fcrtech.cz/bedrijf/-/:id/-",
        nl: "https://uat-goudengids-nl.dev4.fcrtech.cz/nl/bedrijf/-/:id/-"
    },
    Plausible: {
        nl: {
            domain: "uat-scaleup-be.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        },
        fr: {
            domain: "uat-scaleup-be.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        },
        en: {
            domain: "uat-scaleup-be.dev4.fcrtech.cz",
            apiHost: "https://analytics.fcrtech.cz"
        }
    },
    Sentry: {
        isEnabled: true,
        dsn: "https://57f7245461e1ac472e3069a788703100@sentry.fcrmedia.com/56",
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    },
};
